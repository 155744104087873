import ComingSoon from './components/ComingSoon'

function App() {
  const styles = {
    color: 'white',
    width: '100vw',
    height: '100vh',
    display: 'flex',
  }

  return (
    <div style={styles}>
      <ComingSoon />
    </div>
  );
}

export default App
