import React from 'react'

import comingSoon from './coming-soon.6.mp4'

const ComingSoon = () => {
    const styles = {
        height: 'auto',
        width: '100%'
    }
    return (
        // <>the1979bizarre</>
        <video style={styles} autoPlay loop muted playsInline>
            <source src={comingSoon} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    )
}

export default ComingSoon